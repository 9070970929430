<template>
  <pro-menu-layout>
    <div class="q-pa-md">
      <div class="col text-right">
        <q-btn
          v-if="allowRegisterSiteVisit"
          no-caps
          class="col q-ml-sm"
          padding="xs md"
          color="primary"
          :label="getRes('System.Button.Register')"
          rounded
          :to="registerSiteVisitFormRoute"
          style="width: 120px"
        />
      </div>
      <pro-deck
        :title="getRes('Form.Field.SiteVisitAttachment')"
        :cards="siteVisitAttachment"
        v-slot="{ card }"
        v-if="
          siteVisitAttachment[0].files && siteVisitAttachment[0].files.length
        "
      >
        <pro-deck-card v-bind="card" expandable :title="card.title">
          <q-list v-if="card.files && card.files.length">
            <q-item
              class="grey-on-hover"
              v-for="file in card.files"
              :key="file.fileId"
            >
              <pro-file :file="file" show-download-button />
            </q-item>
          </q-list>
        </pro-deck-card>
      </pro-deck>

      <pro-deck
        :title="getRes('Form.Field.RegistrationOfSiteVisit')"
        :cards="cards"
        v-slot="{ card: card }"
      >
        <pro-deck-card :title="card.title" :actions="card.actions" expandable>
          <div class="row">
            <div class="col-auto">
              <pro-key-value-table :rows="tendererTableRows" :data="card" />
            </div>
          </div>
          <template v-if="siteVisitInfo.submitType !== 'Attachment'">
            <br />
            <div class="col-auto">{{ getRes("Form.Field.AttendeeList") }}:</div>
            <div class="row">
              <div
                class="col-12"
                v-for="(row, index) in card.attendeeList"
                :key="index"
              >
                <div class="row">
                  <div class="col-auto">
                    <br />
                    {{ getRes("Form.Field.Attendee") }}{{ index + 1 }}:
                    <pro-key-value-table
                      :rows="tendererAttendeeTableRows"
                      :data="row"
                    />
                  </div>
                </div>
              </div>
            </div>
          </template>

          <pro-folders
            class="q-ma-md"
            :folders="card.fileList"
            show-download-button
          />
        </pro-deck-card>
      </pro-deck>
    </div>
  </pro-menu-layout>
</template>

<script>
import ProMenuLayout from "@/components/PROSmart/Layout/ProMenuLayout";
import ProDeck from "@/components/PROSmart/Deck/ProDeck";
import ProDeckCard from "@/components/PROSmart/Deck/ProDeckCard";
import ProFolders from "../../../../components/PROSmart/ProFolders";
import ProKeyValueTable from "@/components/PROSmart/ProKeyValueTable";
import ProFile from "../../../../components/PROSmart/ProFile";

const viewFormRouteName = "DocumentViewForm";

export default {
  components: {
    ProFile,
    ProMenuLayout,
    ProDeck,
    ProDeckCard,
    ProFolders,
    ProKeyValueTable,
  },
  data() {
    return {
      cards: [],
      siteVisitInfo: {},
      viewFormCode: "",
      registerSiteVisitWorkflowInfo: {},
      fields: [],
      siteVisitAttachment: [],
    };
  },

  computed: {
    tendererTableRows() {
      let returnArray = [];
      let list = this.cards[0];
      if (list.preferredDate != null) {
        returnArray.push({
          label: this.getRes("Form.Field.PreferredDate"),
          field: "preferredDate",
        });
      }
      if (list.firstAlternativeDate != null) {
        returnArray.push({
          label: this.getRes("Form.Field.AlternateDate1"),
          field: "firstAlternativeDate",
        });
      }
      if (list.secondAlternativeDate != null) {
        returnArray.push({
          label: this.getRes("Form.Field.AlternateDate2"),
          field: "secondAlternativeDate",
        });
      }
      return returnArray;
    },
    tendererAttendeeTableRows() {
      let returnArray = [];
      let list = this.cards[0].attendeeList[0];
      if (list.attendeeName != null) {
        returnArray.push({
          label: this.getRes("Form.Field.AttendeeName"),
          field: "attendeeName",
        });
      }
      if (list.position != null) {
        returnArray.push({
          label: this.getRes("Form.Field.PositionTitle"),
          field: "position",
        });
      }
      if (list.contactNumber != null) {
        returnArray.push({
          label: this.getRes("Form.Field.ContactNo"),
          field: "contactNumber",
        });
      }
      if (list.email != null) {
        returnArray.push({
          label: this.getRes("Form.Field.Email"),
          field: "email",
        });
      }
      if (list.personalId != null) {
        returnArray.push({
          label: this.getRes("Form.Field.HKIDPassportNo"),
          field: "personalId",
        });
      }

      return returnArray;
    },
    allowRegisterSiteVisit() {
      return (
        this.registerSiteVisitFormRoute &&
        this.registerSiteVisitWorkflowInfo.visible &&
        this.registerSiteVisitWorkflowInfo.allowCreate
      );
    },
    registerSiteVisitFormRoute() {
      return this.registerSiteVisitWorkflowInfo.workflowCode
        ? {
            name: viewFormRouteName,
            params: {
              mode: "Edit",
              code: this.registerSiteVisitWorkflowInfo.workflowCode,
            },
          }
        : null;
    },
  },
  methods: {
    formatDate(date) {
      return this.$proSmart.common.getFormattedDate(new Date(date + "+0800"));
    },
    clean(obj) {
      for (const x of obj) {
        for (let propName in x) {
          if (x[propName] === null || x[propName] === undefined) {
            delete x[propName];
          }
        }
      }
      return obj;
    },
  },
  async created() {
    this.siteVisitAttachment = await this.$proSmart.documentUi.getSiteVisitRegisterForm(
      this,
      parseInt(this.$route.params.id)
    );
    const getArr = (files) => {
      return Array.from(files, (object) => {
        return Object.keys(object).reduce(
          (obj, key) => {
            obj[key.toLowerCase()] = object[key];
            return obj;
          },
          {
            folderDescription: "",
            fileId: object.FileId,
          }
        );
      });
    };

    Promise.all([
      this.$proSmart.documentUi.getTenderBriefingByTenderer(
        this,
        this.$route.params.id
      ),
      this.$proSmart.tender.getWorkflowCode(this, this.$route.params.id, [
        "reg_site_visit_attachment",
        "reg_site_visit_attachment_restricted",
        "reg_site_visit_web_form",
        "reg_site_visit_web_form_restricted",
      ]),
    ]).then(
      ([
        info,
        [attachment, attachmentRestricted, webForm, webFormRestricted],
      ]) => {
        this.siteVisitInfo = info;

        if (this.siteVisitInfo.submitType === "Attachment") {
          if (this.siteVisitInfo.includedPersonalData) {
            this.viewFormCode = "Supplier_Eoi_SiteVisitRestrict";
            this.registerSiteVisitWorkflowInfo = attachmentRestricted;
          } else {
            this.viewFormCode = "Supplier_Eoi_SiteVisit";
            this.registerSiteVisitWorkflowInfo = attachment;
          }
        } else if (this.siteVisitInfo.submitType === "WebFormRestricted") {
          this.viewFormCode = "Supplier_Eoi_RegForSiteVisitRestrPers";
          this.registerSiteVisitWorkflowInfo = webFormRestricted;
        } else if (this.siteVisitInfo.submitType === "WebFormNonRestricted") {
          this.viewFormCode = "Supplier_Eoi_RegForSiteVisitNonRestr";
          this.registerSiteVisitWorkflowInfo = webForm;
        }
      }
    );

    await this.$proSmart.documentUi
      .getTenderBriefingRegistrationByTenderer(this, this.$route.params.id)
      .then((info) => {
        info.splice(1);
        this.cards = info.map((submission) => ({
          key: submission.id,
          title: this.$proSmart.common.getFormattedDate(
            new Date(submission.submissionDate)
          ),
          tendererId: submission.tendererId,
          attendeeList: submission.attendeeList,
          submissionDate: submission.submissionDate,
          fileList: getArr(submission.tendererFile.fileList),
          preferredDate:
            submission.preferredDate == null
              ? null
              : this.$proSmart.common.getFormattedDate(
                  submission.preferredDate
                ),
          firstAlternativeDate:
            submission.firstAlternativeDate == null
              ? null
              : this.$proSmart.common.getFormattedDate(
                  submission.firstAlternativeDate
                ),
          secondAlternativeDate:
            submission.secondAlternativeDate == null
              ? null
              : this.$proSmart.common.getFormattedDate(
                  submission.secondAlternativeDate
                ),
        }));
      });
    this.cards = this.clean(this.cards);
  },
};
</script>
