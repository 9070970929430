import { render, staticRenderFns } from "./ViewSiteVisitForTenderer.vue?vue&type=template&id=5242cd72&"
import script from "./ViewSiteVisitForTenderer.vue?vue&type=script&lang=js&"
export * from "./ViewSiteVisitForTenderer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QList,QItem});
